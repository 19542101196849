export const LUNCH_SHOP_OPEN = { hour: 0, minute: 0 }
export const LUNCH_SHOP_CLOSE = { hour: 24, minute: 0 }

export const DINNER_SHOP_OPEN = { hour: 0, minute: 0 }
export const DINNER_SHOP_CLOSE = { hour: 24, minute: 0 }

export default {
  TAX_PERCENT: 8,
  REVIEW_BUFFER: 15, // mins

  LUNCH_FIRST_CALL: { hour: 17, minute: 30 },
  LUNCH_LAST_CALL: { hour: 10, minute: 30 },

  DINNER_FIRST_CALL: { hour: 10, minute: 30 },
  DINNER_LAST_CALL: { hour: 17, minute: 30 },

  PICKUP_WINDOW_LENGTH: 15,

  FAKE_LATENCY_MS: 400,
}
