import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import {
  Field,
  reduxForm,
} from 'redux-form'
import {
  Select,
} from 'redux-form-material-ui'
import {
  injectIntl,
  defineMessages,
} from 'react-intl'
import {
  getMyShopHolidays,
  editMyShopHolidays,
} from '../../redux/api/actions'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import MenuItem from '@material-ui/core/MenuItem'
import green from '@material-ui/core/colors/green'
import CloseIcon from '@material-ui/icons/Close'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import CalendarIcon from '../../components/icons/CalendarIcon'
import Constants, {
  LUNCH_SHOP_OPEN,
  LUNCH_SHOP_CLOSE,
  DINNER_SHOP_OPEN,
  DINNER_SHOP_CLOSE,
} from '../../utils/Constants'

const messages = defineMessages({
  missingField: {
    id: 'edit-hours-for-date-dialog.missing-field',
    defaultMessage: 'This field is required.',
  },
  timeRangeInvalid: {
    id: 'edit-hours-for-date-dialog.time-range-invalid',
    defaultMessage: 'Closing time must come after opening time.',
  },
})

const CLOSED_INDICATOR = '--'

const lunchOpen = moment.tz('Asia/Tokyo').startOf('day').set(LUNCH_SHOP_OPEN)
const lunchClose = moment.tz('Asia/Tokyo').startOf('day').set(LUNCH_SHOP_CLOSE)

const dinnerOpen = moment.tz('Asia/Tokyo').startOf('day').set(DINNER_SHOP_OPEN)
const dinnerClose = moment.tz('Asia/Tokyo').startOf('day').set(DINNER_SHOP_CLOSE)

const LUNCH_TIMES = [CLOSED_INDICATOR]
const DINNER_TIMES = [CLOSED_INDICATOR]

while (!lunchOpen.isAfter(lunchClose)) {
  LUNCH_TIMES.push(lunchOpen.clone().format('kk:mm'))
  lunchOpen.add(15, 'm')
}

while (!dinnerOpen.isAfter(dinnerClose)) {
  DINNER_TIMES.push(dinnerOpen.clone().format('kk:mm'))
  dinnerOpen.add(15, 'm')
}

const getHours = (hoursHistory, date) => {
  let hours = {
    lunch: {},
    dinner: {},
  }

  if (date && hoursHistory) {
    const range = hoursHistory.find(range => date.isBetween(moment(range.startsAt), moment(range.endsAt), null, '[)'))

    if (range && range.hours) {
      const dayName = date.clone().locale('en').format('dddd').toLowerCase()

      if (range.hours.lunch[dayName]) {
        const parts = range.hours.lunch[dayName].split('-')

        hours.lunch.start = parts[0]
        hours.lunch.end = parts[1]
      }

      if (range.hours.dinner[dayName]) {
        const parts = range.hours.dinner[dayName].split('-')

        hours.dinner.start = parts[0]
        hours.dinner.end = parts[1]
      }
    }
  }

  return hours
}

const validate = (values, props) => {
  const errors = {}

  for (let mealType of ['lunch', 'dinner']) {
    if (values[`${mealType}-start`] !== CLOSED_INDICATOR && values[`${mealType}-end`] === CLOSED_INDICATOR) {
      errors[`${mealType}-end`] = props.intl.formatMessage(messages.missingField)
    } else if (values[`${mealType}-end`] !== CLOSED_INDICATOR && values[`${mealType}-start`] === CLOSED_INDICATOR) {
      errors[`${mealType}-start`] = props.intl.formatMessage(messages.missingField)
    } else if (values[`${mealType}-start`] !== CLOSED_INDICATOR && values[`${mealType}-end`] !== CLOSED_INDICATOR) {
      const startTime = moment(values[`${mealType}-start`], 'kk:mm')
      const endTime = moment(values[`${mealType}-end`], 'kk:mm')

      /*
       *  Hack for moment js parsing problem with midnight
       */
       const isEndOfDay = endTime.hour() === 0

      if (!isEndOfDay && startTime.isSameOrAfter(endTime)) {
        // errors[`${mealType}-end`] = props.intl.formatMessage(messages.timeRangeInvalid)
      }
    }
  }

  return errors
}


const editHoursStyles = theme => ({
  dialogPaper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxWidth: 'none',
      maxHeight: 'none',
      borderRadius: 0,
      margin: 0,
    },
  },
  content: {
  },
  form: {
    maxWidth: 450,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'center',
  },
  calendarIcon: {
    marginRight: theme.spacing(1),
    marginBottom: -2,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#292529',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  days: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: 16,
    color: '#292529',
    margin: theme.spacing(0, 2, 0, -1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 1, 0, -1),
    }
  },
  times: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  timesLabel: {
    marginRight: theme.spacing(2),
    width: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
      marginTop: theme.spacing(2),
    },
  },
  timesSelects: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  timesSelect: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      minWidth: 150,
    },
  },
  timeDivider: {
    width: theme.spacing(3),
    borderBottom: '1px solid #A8B2B9',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      width: 0,
      height: theme.spacing(2),
      borderRight: '1px solid #A8B2B9',
      margin: theme.spacing(1, 0),
    },
  },
  cta: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 140,
  },
  ctaSuccess: {
    backgroundColor: green[500],
    boxShadow: `0px 8.72651px 17.453px ${green[500]}`,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    position: 'absolute',
  },
})

class EditHoursDialog extends Component {
  state = {
    loading:  false,
    success: false,
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    let holidayToday

    if (this.props.myShopHolidays.data) {
      holidayToday = this.props.myShopHolidays.data.find(holiday => this.props.date.isBetween(moment.tz(holiday.startDate, 'Asia/Tokyo'), moment.tz(holiday.endDate, 'Asia/Tokyo'), 'day', '[]'))
    }

    if (holidayToday) {
      await this.props.editMyShopHolidays(this.props.shop.id, [{
        ...holidayToday,
        value: false
      }])

      await this.props.getMyShopHolidays(this.props.shop.id)
    }

    let hours = {
      lunch: {},
      dinner: {},
    }

    if (this.props.myShopHours.data) {
      hours = this.props.myShopHours.data
    }

    const dayName = this.props.date.clone().locale('en').format('dddd').toLowerCase()

    if (values['lunch-start'] !== CLOSED_INDICATOR && values['lunch-end'] !== CLOSED_INDICATOR) {
      hours.lunch[dayName] = `${values['lunch-start']}-${values['lunch-end']}`
    } else if (values['lunch-start'] === CLOSED_INDICATOR && values['lunch-end'] === CLOSED_INDICATOR) {
      hours.lunch[dayName] = null
    }

    if (values['dinner-start'] !== CLOSED_INDICATOR && values['dinner-end'] !== CLOSED_INDICATOR) {
      hours.dinner[dayName] = `${values['dinner-start']}-${values['dinner-end']}`
    } else if (values['dinner-start'] === CLOSED_INDICATOR && values['dinner-end'] === CLOSED_INDICATOR) {
      hours.dinner[dayName] = null
    }

    if (this.props.onSubmit) {
      await this.props.onSubmit(hours, this.props.date)
    }

    this.setState({
      loading: false,
      success: true,
    })

    setTimeout(() => {
      this.props.onClose()

      setTimeout(() => {
        this.setState({ success: false })
      }, 100)
    }, Constants.FAKE_LATENCY_MS)
  }

  render() {
    let holidayToday

    if (this.props.myShopHolidays.data) {
      holidayToday = this.props.myShopHolidays.data.find(holiday => this.props.date.isBetween(moment.tz(holiday.startDate, 'Asia/Tokyo'), moment.tz(holiday.endDate, 'Asia/Tokyo'), 'day', '[]'))
    }

    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="sm"
        classes={{
          paper: this.props.classes.dialogPaper,
        }}
        onClose={this.props.onClose}>
        <DialogTitle className={this.props.classes.title}>
          <CalendarIcon className={this.props.classes.calendarIcon} />
          {this.props.date.format('LL(ddd)')}
          <IconButton
            aria-label="Close"
            className={this.props.classes.closeButton}
            onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          className={this.props.classes.content}>
          {this.props.myShopHoursHistory.data && (
            <form className={this.props.classes.form}>
              <Typography
                variant="body1"
                className={this.props.classes.subtitle}>
                <FormattedMessage
                  id="edit-hours-for-date.hours"
                  defaultMessage="Hours" />
              </Typography>
              <div className={this.props.classes.times}>
                <Typography
                  variant="body1"
                  className={this.props.classes.timesLabel}>
                  <FormattedMessage
                    id="edit-hours-for-date.lunch"
                    defaultMessage="lunch" />
                </Typography>
                <div className={this.props.classes.timesSelects}>
                  <Field
                    name="lunch-start"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {LUNCH_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                  <div className={this.props.classes.timeDivider} />
                  <Field
                    name="lunch-end"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {LUNCH_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
              <div className={this.props.classes.times}>
                <Typography
                  variant="body1"
                  className={this.props.classes.timesLabel}>
                  <FormattedMessage
                    id="edit-hours-for-date.dinner"
                    defaultMessage="Dinner" />
                </Typography>
                <div className={this.props.classes.timesSelects}>
                  <Field
                    name="dinner-start"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {DINNER_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                  <div className={this.props.classes.timeDivider} />
                  <Field
                    name="dinner-end"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {DINNER_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!this.state.success && ((!holidayToday && this.props.pristine) || this.props.invalid || this.state.loading)}
                className={classNames(this.props.classes.cta, {
                  [this.props.classes.ctaSuccess]: this.state.success,
                })}
                onClick={this.props.handleSubmit(this.onClickSubmit)}>
                {!this.state.success && (
                  <FormattedMessage
                    id="edit-hours-for-date.save"
                    defaultMessage="Save" />
                )}
                {this.state.success && (<CheckRoundedIcon />)}
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className={this.props.classes.buttonProgress} />
                )}
              </Button>
            </form>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let initialValues = {}

  if (ownProps.date && state.api.myShopHoursHistory.default.data) {
    const hours = getHours(state.api.myShopHoursHistory.default.data, ownProps.date)

    initialValues['lunch-start'] = hours.lunch.start || CLOSED_INDICATOR
    initialValues['lunch-end'] = hours.lunch.end || CLOSED_INDICATOR
    initialValues['dinner-start'] = hours.dinner.start || CLOSED_INDICATOR
    initialValues['dinner-end'] = hours.dinner.end || CLOSED_INDICATOR
  }

  return {
    initialValues,
    shop: state.features.shop,
    myShopHours: state.api.myShopHours.default,
    myShopHoursHistory: state.api.myShopHoursHistory.default,
    myShopHolidays: state.api.myShopHolidays.default,
  }
}

const mapDispatchToProps = {
  getMyShopHolidays,
  editMyShopHolidays,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(reduxForm({
  form: 'edit-hours-for-date',
  validate,
  enableReinitialize: true,
})((withStyles(editHoursStyles)(EditHoursDialog)))))
