import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  injectIntl,
  FormattedMessage,
} from 'react-intl'
import ReactCodeInput from 'react-verification-code-input'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import {
  getLineAccounts,
  useLineConfirmationCode,
} from '../../redux/api/actions'
import getEnv from '../../utils/getEnv'
import BigCheckmarkIcon from '../../components/icons/BigCheckmarkIcon'

const potluckLineId = '936iurxz'

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'center',
  },
  content: {
    minHeight: 380,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  qrCode: {
    backgroundImage: `url(https://qr-official.line.me/sid/L/${potluckLineId}.png)`,
    backgroundSize: 'contain',
    height: 220,
    width: 220,
    marginTop: theme.spacing(2),
  },
  confirmationCodeInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& input': {
      fontFamily: 'Hiragino Sans !important',
      width: '40px !important',
      margin: '0 4px !important',
      borderRadius: '3px !important',
      border: '1px solid rgba(0, 0, 0, 0.2) !important',
    },
  },
  error: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  errorIcon: {
    marginRight: theme.spacing(1),
  },
  successTitle: {
    marginTop: theme.spacing(2),
  },
  successText: {
    marginBottom: theme.spacing(2),
  },
  successCta: {
    marginTop: theme.spacing(2),
  },
})

class EditPasswordDialog extends Component {
  state = {
    loading: false,
    success: false,
    error: null,
  }

  onChangeConfirmationCode = () => {
    this.setState({
      error: null,
    })
  }

  onEnterConfirmationCode = async code => {
    this.setState({
      loading: true,
    })

    const result = await this.props.useLineConfirmationCode(this.props.shop.id, code)

    setTimeout(async () => {
      if (result && result.status >= 300) {
        this.setState({
          loading: false,
          error: 'そのコードは誤っています。',
        })
      } else {
        await this.props.getLineAccounts(this.props.shop.id)

        this.setState({
          loading: false,
          success: true,
        })
      }
    }, 400)
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth="sm"
        fullWidth
        onClose={this.props.onClose}>
        <Collapse in={!this.state.success}>
          <DialogTitle className={this.props.classes.title}>
            <FormattedMessage
              id="connect-line-dialog.title"
              defaultMessage="Connect Line" />
            <IconButton
              aria-label="Close"
              className={this.props.classes.closeButton}
              onClick={this.props.onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </Collapse>
        <DialogContent dividers>
          <Collapse in={!this.state.loading && !this.state.success}>
            <div className={this.props.classes.content}>
              <Typography variant="body2">
                <FormattedMessage
                  id="connect-line-dialog.subtitle"
                  defaultMessage="Please scan this QR code in your Line app and then enter the confirmation number that is sent to you." />
              </Typography>
              <div className={this.props.classes.qrCode} />
              <ReactCodeInput
                type="number"
                fields={6}
                className={this.props.classes.confirmationCodeInput}
                fieldWidth={48}
                fieldHeight={52}
                onChange={this.onChangeConfirmationCode}
                onComplete={this.onEnterConfirmationCode} />
            </div>
            {this.state.error && (
              <div className={this.props.classes.error}>
                <ErrorIcon
                  color="error"
                  className={this.props.classes.errorIcon} />
                <Typography
                  variant="body2"
                  color="error">
                  {this.state.error}
                </Typography>
              </div>
            )}
          </Collapse>
          <Collapse in={this.state.loading}>
            <div className={this.props.classes.content}>
              <CircularProgress />
            </div>
          </Collapse>
          <Collapse in={this.state.success}>
            <div className={this.props.classes.content}>
              <Typography
                variant="h6"
                className={this.props.classes.successTitle}>
                <FormattedMessage
                  id="connect-line-dialog.success-title"
                  defaultMessage="Success!" />
              </Typography>
              <Typography
                variant="body2"
                className={this.props.classes.successText}>
                <FormattedMessage
                  id="connect-line-dialog.success-subtitle"
                  defaultMessage="Your Line account is now connected." />
              </Typography>
              <BigCheckmarkIcon />
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.successCta}
                onClick={this.props.onClose}>
                <FormattedMessage
                  id="connect-line-dialog.success-cta"
                  defaultMessage="Close" />
              </Button>
            </div>
          </Collapse>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  shop: state.features.shop
})

const mapDispatchToProps = {
  getLineAccounts,
  useLineConfirmationCode,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(EditPasswordDialog)))
