import React, {
  Component,
  useState,
  useEffect,
} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import classNames from 'classnames'
import {
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { CSVLink } from 'react-csv'
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
} from 'recharts'
import { withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Badge from '@material-ui/core/Badge'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import StarIcon from '@material-ui/icons/Star'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpRounded'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadRounded'
import FilterListIcon from '@material-ui/icons/FilterListRounded'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRightRounded'
import {
  getMyShops,
  getShopPayments,
  getReviews,
  getMeals,
} from '../redux/api/actions'
import canUseDOM from '../utils/canUseDOM'
import promiseMap from '../utils/promiseMap'
import getImageForMeal from '../utils/getImageForMeal'
import formatNumberWithCommas from '../utils/formatNumberWithCommas'
import Toggle from '../components/Toggle'
import ShopSelector from '../components/ShopSelector'
import CommentBubbleIcon from '../components/icons/CommentBubbleIcon'

const DATE_FORMAT_DAY = 'YYYYMMDD'
const DATE_FORMAT_WEEK = 'YYYYMMDD'
const DATE_FORMAT_MONTH = 'YYYYMM'

const FORMAT_MAP = {
  day: DATE_FORMAT_DAY,
  week: DATE_FORMAT_WEEK,
  month: DATE_FORMAT_MONTH,
}

const EARLIEST_DATE = moment('20180801')

const round = number => parseFloat(number).toFixed(1)

const parseDate = date => {
  let period = 'month'
  let startsAt

  if (date && moment(date, DATE_FORMAT_DAY).format(DATE_FORMAT_DAY) === date) {
    period = 'day'
    startsAt = moment(date, FORMAT_MAP[period])
  } else if (date && moment(date, DATE_FORMAT_WEEK).format(DATE_FORMAT_WEEK) === date) {
    period = 'week'
    startsAt = moment(date, FORMAT_MAP[period])
  } else if (date) {
    startsAt = moment(date, FORMAT_MAP[period])
  }

  if (!date || !(startsAt && startsAt.isValid())) {
    startsAt = moment.tz('Asia/Tokyo')
  }

  startsAt.startOf(period)

  return {
    period,
    startsAt,
  }
}

const FiltersDialog = withStyles(theme => ({

}))(({ open, shops, initialValue, onClose, onConfirm }) => {
  const [selectedShopIds, setSelectedShopIds] = useState(initialValue)

  useEffect(() => {
    setSelectedShopIds(initialValue)
  }, open)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <FormattedMessage
          id="sales.select-shops"
          defaultMessage="Select shops" />
      </DialogTitle>
      <ShopSelector
        shops={shops}
        initialValue={initialValue}
        onChange={ids => setSelectedShopIds(ids)} />
      <DialogActions>
        <Button onClick={() => onConfirm(selectedShopIds)} autoFocus>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const SubscriptionsTableRow = withStyles(theme => ({
  root: {
    margin: 0,
  },
  details: {
    backgroundColor: theme.palette.grey[100],
  },
  cellRoot: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  cellHeader: {
    fontWeight: 'bold',
  },
  cellIcon: {
    width: 60,
    margin: 0,
  },
  cellSmall: {
    width: 160,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 160,
    }
  },
  cellIconAndText: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  arrowIcon: {
    marginBottom: -4,
    marginRight: 8,
  }
}))(({ startOpen, product, classes }) => {
  const [open, setOpen] = React.useState(!!startOpen)

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell className={classes.cellIcon}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{product.name}</TableCell>
        <TableCell
          align="right"
          className={classes.cellSmall}>
          {product.count}
          </TableCell>
        <TableCell
          align="right"
          className={classes.cellSmall}>
          {formatNumberWithCommas(product.revenue)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={4}
          className={classes.cellRoot}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit>
            <Box className={classes.details}>
              <Table>
                <TableBody>
                  {product.items.map(subscription => (
                    <TableRow key={subscription.id}>
                      <TableCell className={classNames([classes.cellNarrow, classes.cellIcon])} />
                      <TableCell className={classes.cellNarrow}>Subscription for {subscription.user} (renewed {moment.tz(subscription.date, 'Asia/Tokyo').format('LL')})</TableCell>
                      <TableCell
                        align="right"
                        className={classNames([classes.cellNarrow, classes.cellSmall])}>
                        {formatNumberWithCommas(subscription.revenue)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
})

const OrdersTable = withStyles(theme => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(),
  },
  cellNarrow: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    fontSize: 12,
  },
  cellIcon: {
    width: 63,
    margin: 0,
  },
  cellSmall: {
    width: 160,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 160,
    }
  },
  cellRight: {
    textAlign: 'right',
  },
}))(({ startOpen, orders, classes }) => {
  const [open, setOpen] = React.useState(!!startOpen)

  return (
    <Table>
      <TableBody>
        {orders.map(order => (
          <TableRow key={order.id}>
            <TableCell className={classNames([classes.cellNarrow, classes.cellIcon])} />
            <TableCell className={classes.cellNarrow}>Order from {order.user} on {moment.tz(order.date, 'Asia/Tokyo').format('LL')}</TableCell>
            <TableCell
              align="right"
              className={classNames([classes.cellNarrow, classes.cellSmall])}>
              {formatNumberWithCommas(order.revenue)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const OrderTableRow = withStyles(theme => ({
  root: {
    margin: 0,
  },
  details: {
    backgroundColor: theme.palette.grey[100],
  },
  cellRoot: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  cellHeader: {
    fontWeight: 'bold',
  },
  cellIcon: {
    width: 60,
    margin: 0,
  },
  cellSmall: {
    width: 160,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 160,
    }
  },
  cellIconAndText: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  arrowIcon: {
    marginBottom: -4,
    marginRight: 8,
  }
}))(({ startOpen, shop, classes }) => {
  const [open, setOpen] = React.useState(!!startOpen)
  const [itemOpenArr, setItemOpenArr] = React.useState(shop.items.map(() => !!startOpen))

  const toggleIndexOpen = index => {
    const itemOpenArrCopy = [...itemOpenArr]
    itemOpenArrCopy[index] = !itemOpenArrCopy[index]
    setItemOpenArr(itemOpenArrCopy)
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell className={classes.cellIcon}>
          <IconButton
            disabled={!shop.items || shop.items.length === 0}
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{shop.name}</TableCell>
        <TableCell
          align="right"
          className={classes.cellSmall}>
          {shop.count}
          </TableCell>
        <TableCell
          align="right"
          className={classes.cellSmall}>
          {formatNumberWithCommas(shop.revenue)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={4}
          className={classes.cellRoot}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit>
            <Box className={classes.details}>
              <Table>
                <TableBody>
                  {shop.items.map((item, index) => [(
                    <TableRow
                      id="item"
                      className={classes.root}>
                      <TableCell className={classes.cellIcon}>
                        <IconButton aria-label="expand row" size="small" onClick={() => toggleIndexOpen(index)}>
                          {itemOpenArr[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell
                        align="right"
                        className={classes.cellSmall}>
                        {item.count}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.cellSmall}>
                        {formatNumberWithCommas(item.revenue)}
                      </TableCell>
                    </TableRow>
                  ), (
                    <TableRow id="details">
                      <TableCell
                        colSpan={4}
                        className={classes.cellRoot}>
                        <Collapse
                          in={itemOpenArr[index]}
                          timeout="auto"
                          unmountOnExit>
                          <Box>
                            <OrdersTable
                              startOpen={startOpen}
                              orders={item.items} />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )])}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
})

const ReviewsTable = withStyles(theme => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(),
  },
  cellNarrow: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    fontSize: 12,
  },
  cellTiny: {
    width: 72,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 160,
    }
  },
  starIcon: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(1),
    marginBottom: -2,
  },
  cellRight: {
    textAlign: 'right',
  },
}))(({ reviews, classes }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Table>
      <TableBody>
        {reviews.map(review => (
          <TableRow key={review.id}>
            <TableCell
              align="right"
              className={classNames([classes.cellNarrow, classes.cellTiny])}>
              <StarIcon
                color="primary"
                className={classes.starIcon} />
              {review.rating}
            </TableCell>
            <TableCell className={classes.cellNarrow}>{review.comment || 'No comment'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const ReviewsTableRow = withStyles(theme => ({
  root: {
    margin: 0,
  },
  details: {
    backgroundColor: theme.palette.grey[100],
  },
  cellRoot: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  cellHeader: {
    fontWeight: 'bold',
  },
  cellIcon: {
    width: 60,
    margin: 0,
  },
  cellSmall: {
    width: 160,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 160,
    }
  },
  starIcon: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(1),
    marginBottom: -2,
  },
  commentsIcon: {
    width: 28,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    float: 'right',
  },
  cellIconAndText: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  arrowIcon: {
    marginBottom: -4,
    marginRight: 8,
  }
}))(({ shop, classes }) => {
  const [open, setOpen] = React.useState(false)
  const [itemOpenArr, setItemOpenArr] = React.useState(shop.items.map(() => false))

  const toggleIndexOpen = index => {
    const itemOpenArrCopy = [...itemOpenArr]
    itemOpenArrCopy[index] = !itemOpenArrCopy[index]
    setItemOpenArr(itemOpenArrCopy)
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell className={classes.cellIcon}>
          <IconButton
            disabled={!shop.items || shop.items.length === 0}
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{shop.name}</TableCell>
        <TableCell
          align="right"
          className={classes.cellSmall}>
          <StarIcon
            color="primary"
            className={classes.starIcon} />
          {Number.isFinite(shop.average) ? round(shop.average) : '----'}
          </TableCell>
        <TableCell
          align="right"
          className={classes.cellSmall}>
          <CommentBubbleIcon
            number={shop.count}
            className={classes.commentsIcon} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={4}
          className={classes.cellRoot}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit>
            <Box className={classes.details}>
              <Table>
                <TableBody>
                  {shop.items.map((item, index) => [(
                    <TableRow
                      id="item"
                      className={classes.root}>
                      <TableCell className={classes.cellIcon}>
                        <IconButton aria-label="expand row" size="small" onClick={() => toggleIndexOpen(index)}>
                          {itemOpenArr[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell
                        align="right"
                        className={classes.cellSmall}>
                        <StarIcon
                          color="primary"
                          className={classes.starIcon} />
                        {typeof item.average === 'number' ? round(item.average) : '----'}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.cellSmall}>
                        {item.count}
                      </TableCell>
                    </TableRow>
                  ), (
                    <TableRow id="details">
                      <TableCell
                        colSpan={4}
                        className={classes.cellRoot}>
                        <Collapse
                          in={itemOpenArr[index]}
                          timeout="auto"
                          unmountOnExit>
                          <Box>
                            <ReviewsTable reviews={item.items} />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )])}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
})

const styles = theme => ({
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: 24,
    color: '#292529',
  },
  topbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topbarLeft: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      justifyContent: 'space-between',
    },
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#292529',
    minWidth: 100,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginLeft: 0,
    },
  },
  subtitleWide: {
    minWidth: 285,
  },
  changeHoursCta: {
    border: '2px solid #FF6069',
    borderRadius: 4,
    '&:hover': {
      border: '2px solid #FF6069',
      borderRadius: 4,
    }
  },
  toggle: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  toggleMargin: {
    marginTop: theme.spacing(2),
  },
  section: {
    background: '#FFFFFF',
    border: '1px solid #EFEFEF',
    marginTop: theme.spacing(2),
  },
  sectionContent: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  sectionActions: {
    display: 'flex',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  summaryItems: {
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  },
  summaryItem: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: theme.spacing(1, 0),
    },
  },
  summaryLabel: {
    fontSize: 16,
    color: '#A8B2B9',
  },
  summaryValue: {
    fontSize: 36,
    fontWeight: 600,
    color: '#292529',
    marginTop: -4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  actions: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(),
  },
  shopsSelector: {
    position: 'absolute',
    top: 30,
    right: 0,
    width: 300,
  },
  mealsRanking: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  mealsRankingContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  mealsRankingItem: {
    flex: 1,
  },
  mealsRankingItemMargin: {
    flex: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  mealsRankingItemTop: {
    display: 'flex',
    alignItems: 'center',
  },
  crown: {
    marginRight: theme.spacing(1),
  },
  mealRankingTitle: {
    fontSize: 16,
    color: '#A8B2B9',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  mealRankingLabel: {
    fontSize: 16,
    color: '#292529',
  },
  mealsRankingValue: {
    fontSize: 16,
    color: '#292529',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
  },
  cellIcon: {
    width: 60,
  },
  cellSmall: {
    width: 160,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 160,
    }
  },
  cellEmpty: {
    textAlign: 'center',
  },
  sectionTitle: {
    fontSize: 24,
    color: '#292529',
    marginTop: theme.spacing(4),
  },
  ratingsItems: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  ratingsTotals: {
    flex: 2,
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      padding: theme.spacing(2),
    },
  },
  ratingsChart: {
    flex: 3,
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      padding: theme.spacing(2),
      width: 'calc(125% - 3px)',
      marginLeft: 'calc(-6% - 18px)',
    },
  },
  ratingsSummary: {
    display: 'flex',
    alignItems: 'center',
  },
  ratingsSummaryStar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(0.5),
  },
  ratingsSummaryText: {
    fontSize: 24,
    fontWeight: 600,
  },
  ratingsSummaryCount: {
    fontSize: 16,
    color: '#A8B2B9',
  },
  cellHeader: {
    fontWeight: 'bold',
  },
})

const fetchData = (dispatch, params, shop) => {
  const {
    period,
    startsAt,
  } = parseDate(params.date)

  const apiProps = {
    companyId: shop.company.id,
    startsAt,
    endsAt: startsAt.clone().add(1, period),
  }

  return promiseMap({
    myShops: dispatch(getMyShops()),
    shopPayments: dispatch(getShopPayments(apiProps)),
    reviews: dispatch(getReviews(apiProps)),
    meals: dispatch(getMeals()),
  })
}

class Sales extends Component {
  constructor(props) {
    super(props)

    const {
      period,
      startsAt,
    } = parseDate(this.props.match.params.date)

    startsAt.startOf(period)

    if (!this.props.match.params.date || startsAt.format(FORMAT_MAP[period]) !== this.props.match.params.date) {
      this.props.history.push(`/sales/${startsAt.format(FORMAT_MAP[period])}`)
    }

    const state = {
      filtersOpen: false,
      period,
      startsAt,
      endsAt: startsAt.clone().add(1, period),
      selectedShopIds: [],
      companyId: props.shop.company.id,
    }

    if (props.myShops.data) {
      state.selectedShopIds = props.myShops.data.map(shop => shop.id)
    }

    this.state = state
  }

  refreshData = () => {
    const apiProps = {
      companyId: this.state.companyId,
      startsAt: this.state.startsAt,
      endsAt: this.state.endsAt,
      shopIds: this.state.selectedShopIds,
    }

    this.props.getShopPayments(apiProps)
    this.props.getReviews(apiProps)
  }

  hasLoaded = () => {
    return this.props.myShops.loaded
      && this.props.paymentSchedule && this.props.paymentSchedule.loaded
  }

  onClickDatePrevious = () => {
    const date = this.state.startsAt.clone().subtract(1, this.state.period)
    this.props.history.push(`/sales/${date.format(FORMAT_MAP[this.state.period])}`)

    this.setState({
      startsAt: date.clone(),
      endsAt: date.clone().add(1, this.state.period),
    }, this.refreshData)
  }

  onClickDateNext = () => {
    const date = this.state.startsAt.clone().add(1, this.state.period)
    this.props.history.push(`/sales/${date.format(FORMAT_MAP[this.state.period])}`)

    this.setState({
      startsAt: date.clone(),
      endsAt: date.clone().add(1, this.state.period),
    }, this.refreshData)
  }

  onClickFilter = () => {
    this.setState({
      filtersOpen: !this.state.filtersOpen,
    })
  }

  onCloseFilters = () => {
    this.setState({
      filtersOpen: false,
    })
  }

  onConfirmFilters = selectedShopIds => {
    this.setState({
      filtersOpen: false,
      selectedShopIds,
    }, this.refreshData)
  }

  onSelectPeriod = period => {
    let date = this.state.startsAt.clone()

    if (period === 'month' && this.state.startsAt.date() >= 15) {
      date.add(1, 'month')
    }

    this.setState({
      period,
      startsAt: this.state.startsAt.clone(),
      endsAt: this.state.startsAt.clone().add(1, period),
    }, this.refreshData)

    this.props.history.push(`/sales/${date.format(FORMAT_MAP[period])}`)
  }

  getCsvData = () => {
    const data = []
    let filename

    if (this.props.locale === 'ja') {
      if (this.state.period === 'day') {
        filename = `売上-${this.state.startsAt.format('LL')}.csv`
      } else {
        filename = `売上-${this.state.startsAt.format('LL')}-${this.state.endsAt.format('LL')}.csv`
      }

      // data.push(['店舗名', 'メニュー名', 'ユーザー', '注文日', 'サイドメニュー/オプション', '総注文数', '売上'])
      data.push(['Shop', 'Menu name', 'User name', 'Order year', 'Date', 'Time', 'Option', 'Number of orders', 'Unit price', 'total'])
    } else {
      if (this.state.period === 'day') {
        filename = `sales-${this.state.startsAt.format('LL')}.csv`
      } else {
        filename = `sales-${this.state.startsAt.format('LL')}-${this.state.endsAt.format('LL')}.csv`
      }

      data.push(['Shop', 'Menu name', 'User name', 'Order year', 'Date', 'Time', 'Option', 'Number of orders', 'Unit price', 'total'])
    }

    if (this.props.shopPayments && this.props.shopPayments.data && this.props.shopPayments.data.orders) {
      for (let shop of this.props.shopPayments.data.orders) {
        data.push([shop.name, '', '', '', '', '', '', shop.count, '', shop.revenue])

        for (let meal of shop.items) {
          // data.push(['', meal.name, '', '', '', '', '', meal.count, meal.price, meal.revenue])

          for (let item of meal.items) {
            const date = moment.tz(item.createdAt, 'Asia/Tokyo')

            data.push(['', meal.name, item.user, date.format('YYYY'), date.format('M/D'), date.format('kk:mm'), '', 1, item.revenue, item.revenue])
          }
        }
      }

      data.push(['', '', '', '', '', '', '', '', this.props.shopPayments.data.totalOrders, formatNumberWithCommas(this.props.shopPayments.data.totalRevenue)])
    }

    return {
      data,
      filename,
    }
  }

  onClickMeal = meal => {
    this.props.history.push(`/sales/${this.state.startsAt.format(DATE_FORMAT_MONTH)}/reviews/${meal.id}`)
  }

  async componentWillMount() {

    if (!this.hasLoaded()) {
      const results = await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

      if (results.myShops) {
        this.setState({
          selectedShopIds: results.myShops.map(shop => shop.id)
        })
      }
    }
  }

  async componentDidUpdate(prevProps){
    if (prevProps.shop.id !== this.props.shop.id) {
      this.setState({
        loading: true,
      })
      await fetchData(this.props.dispatch, this.props.match.params, this.props.shop)

      this.setState({
        loading: false,
      })
    }
  }

  render() {
    const now = moment.tz('Asia/Tokyo')
    let canClickDatePrevious = this.state.startsAt.isAfter(EARLIEST_DATE, this.state.period)
    let canClickDateNext = !canUseDOM() || this.state.startsAt.isBefore(now, this.state.period)
    const shopPaymentsData = this.props.shopPayments && this.props.shopPayments.data
    const reviewsData = this.props.reviews && this.props.reviews.data

    let badgeNumber = 0

    if (this.props.myShops.data && this.state.selectedShopIds.length < this.props.myShops.data.length) {
      badgeNumber = this.state.selectedShopIds.length
    }

    const csvData = this.getCsvData()

    return (
      <div className={this.props.classes.container}>
        <FiltersDialog
          open={this.state.filtersOpen}
          shops={this.props.myShops.data}
          initialValue={this.state.selectedShopIds}
          onClose={this.onCloseFilters}
          onConfirm={this.onConfirmFilters} />
        <Typography
          variant="h6"
          className={this.props.classes.title}>
          <FormattedMessage
            id="sales.title"
            defaultMessage="Sales" />
        </Typography>
        <div className={this.props.classes.topbar}>
          <div className={this.props.classes.topbarLeft}>
            <Typography
              variant="body1"
              className={classNames(this.props.classes.subtitle, {
                [this.props.classes.subtitleWide]: this.state.period === 'week',
              })}>
              {this.state.period === 'day' && (
                <span>
                  {this.state.startsAt.format('Do MMM YYYY')}
                </span>
              )}
              {this.state.period === 'week' && (
                <span>
                  {this.state.startsAt.format('Do MMM YYYY')} - {this.state.startsAt.clone().add(1, 'week').format('Do MMM YYYY')}
                </span>
              )}
              {this.state.period === 'month' && this.state.startsAt.format('MMM YYYY')}
            </Typography>
            <div className={classNames(this.props.classes.pageButtons, this.props.classes.pageButtonsMobile)}>
              <IconButton
                size="small"
                disabled={!canClickDatePrevious}
                onClick={this.onClickDatePrevious}>
                <NavigateBeforeRoundedIcon />
              </IconButton>
              <IconButton
                size="small"
                disabled={!canClickDateNext}
                onClick={this.onClickDateNext}>
                <NavigateNextRoundedIcon />
              </IconButton>
            </div>
          </div>
          <div>
            <Toggle
              items={[{
                label: (
                  <FormattedMessage
                    id="sales.daily"
                    defaultMessage="Daily" />
                ),
                value: 'day',
              }, {
                label: (
                  <FormattedMessage
                    id="sales.weekly"
                    defaultMessage="Weekly" />
                ),
                value: 'week',
              }, {
                label: (
                  <FormattedMessage
                    id="sales.monthly"
                    defaultMessage="Monthly" />
                ),
                value: 'month',
              }]}
              value={this.state.period}
              onSelect={this.onSelectPeriod}
              className={this.props.classes.toggle} />
          </div>
        </div>
        <div className={this.props.classes.section}>
          <div className={this.props.classes.summaryItems}>
            <div className={this.props.classes.summaryItem}>
              <Typography
                variant="body2"
                className={this.props.classes.summaryLabel}>
                <FormattedMessage
                  id="sales.revenue"
                  defaultMessage="Revenue" />
              </Typography>
              <Typography
                variant="h6"
                className={this.props.classes.summaryValue}>
                {shopPaymentsData ? `¥${formatNumberWithCommas(shopPaymentsData.totalRevenue)}` : '¥--------'}
              </Typography>
            </div>
            <div className={this.props.classes.summaryItem}>
              <Typography
                variant="body2"
                className={this.props.classes.summaryLabel}>
                <FormattedMessage
                  id="sales.orders"
                  defaultMessage="Orders" />
              </Typography>
              <Typography
                variant="h6"
                className={this.props.classes.summaryValue}>
                {shopPaymentsData ? formatNumberWithCommas(shopPaymentsData.totalOrders) : '---'}
              </Typography>
            </div>
            <div className={this.props.classes.summaryItem}>
              <Typography
                variant="body2"
                className={this.props.classes.summaryLabel}>
                <FormattedMessage
                  id="sales.subscribers"
                  defaultMessage="Subscribers" />
              </Typography>
              <Typography
                variant="h6"
                className={this.props.classes.summaryValue}>
                {shopPaymentsData ? formatNumberWithCommas(shopPaymentsData.totalSubscriptions) : '----'}
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <CSVLink {...csvData}>
                <IconButton
                  color="primary"
                  variant="outlined">
                  <CloudDownloadIcon />
                </IconButton>
              </CSVLink>
              <IconButton
                key="filter-button"
                color="primary"
                onClick={this.onClickFilter}>
                <Badge
                  badgeContent={badgeNumber}
                  color="primary">
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </div>
          </div>
          {shopPaymentsData && (
            <Table className={this.props.classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={this.props.classes.cellIcon} />
                  <TableCell className={this.props.classes.cellHeader}>
                    <FormattedMessage
                      id="sales.item"
                      defaultMessage="Item" />
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([this.props.classes.cellSmall, this.props.classes.cellHeader])}>
                    <FormattedMessage
                      id="sales.orders"
                      defaultMessage="Orders" />
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([this.props.classes.cellSmall, this.props.classes.cellHeader])}>
                    <FormattedMessage
                      id="sales.revenue"
                      defaultMessage="Revenue" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shopPaymentsData.subscriptions.map((item, index) => (
                  <SubscriptionsTableRow
                    key={index}
                    startOpen={shopPaymentsData.orders.length === 1 && (item.items && item.items.length > 0)}
                    product={item} />
                ))}
                {shopPaymentsData.orders.map((item, index) => (
                  <OrderTableRow
                    key={index}
                    startOpen={shopPaymentsData.orders.length == 1 && (item.items && item.items.length > 0)}
                    shop={item} />
                ))}
                {shopPaymentsData.subscriptions.length === 0 && shopPaymentsData.orders.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan="4"
                      className={this.props.classes.cellEmpty}>
                      <FormattedMessage
                        id="sales.no-sales"
                        defaultMessage="No sales" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        <Typography
          key="reviews-title"
          variant="h6"
          className={this.props.classes.sectionTitle}>
          <FormattedMessage
            id="sales.reviews"
            defaultMessage="Reviews" />
        </Typography>
        <div
          key="reviews-body"
          className={this.props.classes.section}>
          <div className={this.props.classes.ratingsItems}>
            <div className={this.props.classes.ratingsTotals}>
              <div className={this.props.classes.ratingsSummary}>
                <StarIcon
                  color="primary"
                  className={this.props.classes.ratingsSummaryStar} />
                <Typography
                  variant="h6"
                  className={this.props.classes.ratingsSummaryText}>
                  {reviewsData && Number.isFinite(reviewsData.average) ? round(reviewsData.average) : '---'}
                </Typography>
              </div>
              <Typography
                variant="body2"
                className={this.props.classes.ratingsSummaryCount}>
                <FormattedMessage
                  id="sales.reviews-count"
                  defaultMessage="Reviews: {count}"
                  values={{
                    count: (reviewsData ? formatNumberWithCommas(reviewsData.count) : '---'),
                  }} />
              </Typography>
            </div>
            {reviewsData && (
              <div className={this.props.classes.ratingsChart}>
                <ResponsiveContainer
                  width="100%"
                  height={143}>
                  <BarChart
                    layout="vertical"
                    data={reviewsData.chartData}>
                    <XAxis
                      hide
                      type="number"
                      domain={[0, 4]} />
                    <YAxis
                      dataKey="key"
                      type="category"
                      axisLine={false}
                      tickLine={false}
                      fontSize={12}
                      stroke="#A8B2B9" />
                    <YAxis
                      yAxisId={1}
                      dataKey="value"
                      type="category"
                      orientation="right"
                      axisLine={false}
                      tickLine={false}
                      fontSize={12}
                      stroke="#A8B2B9" />
                    <Bar
                      dataKey="value"
                      barSize={16}
                      background
                      fill="#FF6069" />
                 </BarChart>
               </ResponsiveContainer>
              </div>
            )}
          </div>
          {reviewsData && (
            <Table className={this.props.classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={this.props.classes.cellIcon} />
                  <TableCell className={this.props.classes.cellHeader}>
                    <FormattedMessage
                      id="sales.reviews-item"
                      defaultMessage="Item" />
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([this.props.classes.cellSmall, this.props.classes.cellHeader])}>
                    <FormattedMessage
                      id="sales.reviews-rating"
                      defaultMessage="Rating" />
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classNames([this.props.classes.cellSmall, this.props.classes.cellHeader])}>
                    <FormattedMessage
                      id="sales.reviews"
                      defaultMessage="Reviews" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewsData.shops.map((shop, index) => (
                  <ReviewsTableRow
                    key={index}
                    shop={shop} />
                ))}
                {reviewsData.shops.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan="4"
                      className={this.props.classes.cellEmpty}>
                      <FormattedMessage
                        id="sales.no-reviews"
                        defaultMessage="No reviews" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locale: state.features.locale,
  salesDate: moment.tz(state.features.salesDate, 'Asia/Tokyo').startOf('day'),
  shop: state.features.shop,
  myShops: state.api.myShops.default,
  shopPayments: state.api.shopPayments[state.api.shopPayments.lastRequestedKey],
  reviews: state.api.reviews[state.api.reviews.lastRequestedKey],
  meals: state.api.meals.default,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getMyShops,
    getShopPayments,
    getReviews,
  }, dispatch),
  dispatch,
})

Sales.fetchData = fetchData

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(injectIntl(Sales))))
