import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded'
import {
  setHoursDate,
} from '../redux/features/actions'
import {
  getHolidays,
  getShopHours,
  editShopHours,
  getShopHoursHistory,
  getMyShopHolidays,
  getReservationsForDate,
} from '../redux/api/actions'
import promiseMap from '../utils/promiseMap'
import Constants, {
  LUNCH_SHOP_OPEN,
  LUNCH_SHOP_CLOSE,
  DINNER_SHOP_OPEN,
  DINNER_SHOP_CLOSE,
} from '../utils/Constants'
import EditHoursDialog from './dialogs/EditHoursDialog'
import EditHoursForDateDialog from './dialogs/EditHoursForDateDialog'
import CalendarIcon from '../components/icons/CalendarIcon'
import BigCheckmarkIcon from '../components/icons/BigCheckmarkIcon'
import WarningIcon from '../components/icons/WarningIcon'

const DRAWER_WIDTH = 240

const DATE_FORMAT = 'YYYYMM'
const DAY_NAME_FORMAT = 'ddd'

const lunchOpen = moment.tz('Asia/Tokyo').startOf('day').set(LUNCH_SHOP_OPEN)
const lunchClose = moment.tz('Asia/Tokyo').startOf('day').set(LUNCH_SHOP_CLOSE)

const dinnerOpen = moment.tz('Asia/Tokyo').startOf('day').set(DINNER_SHOP_OPEN)
const dinnerClose = moment.tz('Asia/Tokyo').startOf('day').set(DINNER_SHOP_CLOSE)

const LUNCH_TIMES = []
const DINNER_TIMES = []

while (!lunchOpen.isAfter(lunchClose)) {
  LUNCH_TIMES.push(lunchOpen.clone().format('kk:mm'))
  lunchOpen.add(15, 'm')
}

while (!dinnerOpen.isAfter(dinnerClose)) {
  DINNER_TIMES.push(dinnerOpen.clone().format('kk:mm'))
  dinnerOpen.add(15, 'm')
}

const EditDatesDialog = withStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'center',
  },
  calendarIcon: {
    marginRight: theme.spacing(1),
    marginBottom: -2,
  },
  content: {
    textAlign: 'center',
  },
  cta: {
    minWidth: 180,
    border: '2px solid #FF6069',
    borderRadius: 4,
    '&:hover': {
      border: '2px solid #FF6069',
      borderRadius: 4,
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ctaClockIcon: {
    marginRight: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
  },
}))(({ open, closedDateLoading, date, onClose, onClickEditHoursForDate, onClickSetClosedForDate, classes }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth="sm"
    onClose={onClose}>
    <DialogTitle className={classes.title}>
      <CalendarIcon className={classes.calendarIcon} />
      {date.format('LL(ddd)')}
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent
      dividers
      className={classes.content}>
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.cta}
          onClick={onClickEditHoursForDate}>
          <AccessTimeRoundedIcon
            color="primary"
            className={classes.ctaClockIcon} />
          <FormattedMessage
            id="hours.edit-hours-for-day"
            defaultMessage="Edit hours" />
        </Button>
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classes.cta}
          onClick={() => onClickSetClosedForDate(date)}>
          <FormattedMessage
            id="hours.set-closed"
            defaultMessage="Set closed" />
          {closedDateLoading && (
            <CircularProgress
              size={24}
              className={classes.buttonProgress} />
          )}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
))

const ExistingReservationsWarningDialog = withStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'center',
  },
  calendarIcon: {
    marginRight: theme.spacing(1),
    marginBottom: -2,
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  warning: {
    // margin: theme.spacing(2, 0),
  },
  text: {
    margin: theme.spacing(2, 0),
    fontWeight: 600,
  },
  cta: {
    minWidth: 180,
    border: '2px solid #FF6069',
    borderRadius: 4,
    '&:hover': {
      border: '2px solid #FF6069',
      borderRadius: 4,
    },
    margin: theme.spacing(2, 0),
  },
}))(({ open, date, reservations, onClose, onClickAccept, classes }) => {
  const tomorrow = moment().add(1, 'day')

  let dayName = (
    <FormattedMessage
      id="hours.today"
      defaultMessage="today" />
  )

  if (date && date.isSame(tomorrow, 'day')) {
    dayName = (
      <FormattedMessage
        id="hours.tomorrow"
        defaultMessage="tomorrow" />
    )
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}>
      <DialogTitle className={classes.title}>
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <WarningIcon className={classes.warning} />
        <Typography
          variant="body1"
          className={classes.text}>
          <FormattedMessage
            id="hours.reservations-warning"
            defaultMessage="You have {count} {count, plural, one {reservation} other {reservations}} {dayName}. You can still change your hours, but you will need to serve those existing reservations"
            values={{
              count: reservations.length,
              dayName,
            }} />
        </Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.cta}
            onClick={() => onClickAccept(date)}>
            <FormattedMessage
              id="hours.accept"
              defaultMessage="Accept" />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
})

const ClosedDateSuccessDialog = withStyles(theme => ({
  content: {
    textAlign: 'center',
  },
  text: {
    fontSize: 18,
    fontWeight: 600,
    color: '#2C3135',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cta: {
    minWidth: 180,
    borderWidth: 2,
    borderRadius: 4,
    '&:hover': {
      borderWidth: 2,
      borderRadius: 4,
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))(({ open, date, onClose, classes }) => (
  <Dialog
    open={open}
    fullWidth
    maxWidth="sm"
    onClose={onClose}>
    <DialogContent
      dividers
      className={classes.content}>
      <BigCheckmarkIcon />
      <Typography
        variant="body1"
        className={classes.text}>
        <FormattedMessage
          id="hours.calendar-updated"
          defaultMessage="Calendar is updated"
          values={{
            br: (<br />),
          }} />
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        className={classes.cta}
        onClick={onClose}>
        <FormattedMessage
          id="hours.dismiss"
          defaultMessage="Dismiss" />
      </Button>
    </DialogContent>
  </Dialog>
))

const Calendar = withStyles(theme => ({
  table: {
    background: '#FFFFFF',
    border: '1px solid #EFEFEF',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
  },
  headRow: {
    height: theme.spacing(8),
  },
  headText: {
    fontSize: 12,
    color: '#999da1',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cell: {
    position: 'relative',
    border: '1px solid #EFEFEF',
  },
  cellClickable: {
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      transition: 'box-shadow 0.1s linear',
      '&:hover': {
        boxShadow: '0 5px 5px 0 rgba(0, 0, 0, .2)',
        '& #date-text': {
          color: 'black',
        },
      },
    },
  },
  cellGray: {
    backgroundColor: '#F8F7F8',
  },
  cellDisabled: {
    backgroundColor: '#CFD5DA',
  },
  today: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#FF6069',
    height: 30,
    width: 30,
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      top: 10,
      left: 'calc(50% - 15px)',
      right: 'calc(50% - 15px)',
    },
  },
  todayText: {
    fontSize: 13,
    color: '#ffffff',
  },
  dateText: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    fontSize: 13,
    color: '#8A888A',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      left: 0,
      textAlign: 'center',
    },
  },
  dateTextGray: {
    color: '#C4C2C4',
  },
  cellTextContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: 0,
    right: 0,
  },
  cellText: {
    fontSize: 12,
    lineHeight: '18px',
    color: '#8A888A',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 7,
    },
  },
  cellTextLight: {
    color: '#FFFFFF',
  },
  holidaysContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(),
    right: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      top: 0,
      left: 0,
      right: 0,
    },
  },
  holiday: {
    backgroundColor: '#FD9B5B',
    padding: theme.spacing(),
    borderRadius: 5,
    marginBottom: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  holidayInactive: {
    backgroundColor: '#ffffff',
    border: '1px solid #FD9B5B',
  },
  holidayText: {
    fontSize: 10,
    lineHeight: '15px',
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
      display: 'block',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
      overflow: 'hidden',
      maxHeight: '13px',
      lineHeight: '13px',
    },
  },
  holidayTextInactive: {
    color: '#FD9B5B',
  },
}))(({ date, hoursHistory=[], holidays=[], className, onClickDate, classes }) => {
  const daysOfWeek = []
  const daysOfWeekEnglish = []

  for (let i = 0; i < 7; i++) {
    daysOfWeek.push(moment().day(i + 1).format(DAY_NAME_FORMAT))
    daysOfWeekEnglish.push(moment().clone().locale('en').day(i + 1).format('dddd').toLowerCase())
  }

  const weeks = []
  const firstDate = date.clone().startOf('month').startOf('isoweek')
  const lastDate = date.clone().endOf('month').endOf('isoweek')

  const dateCouter = firstDate.clone()

  while (dateCouter.isBefore(lastDate)) {
    const week = []

    for (let i = 0; i < 7; i++) {
      week.push(dateCouter.clone())
      dateCouter.add(1, 'day')
    }

    weeks.push(week)
  }

  const now = moment.tz('Asia/Tokyo').startOf('day')

  return (
    <table
      border="0"
      cellSpacing="0"
      cellPadding="0"
      width="100%"
      height="100%"
      className={classNames(classes.table, className)}>
      <thead>
        <tr className={classes.headRow}>
          {daysOfWeek.map(day => (
            <td key={day}>
              <Typography
                variant="body1"
                className={classes.headText}>
                {day}
              </Typography>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week, index) => (
          <tr key={index}>
            {week.map((day, dayIndex) => {
              const isInFuture = day.isSameOrAfter(now, 'day')
              const isToday = day.isSame(now, 'day')
              const isThisMonth = day.isSame(date, 'month')
              let hoursForDay

              if (hoursHistory) {
                hoursForDay = hoursHistory.find(hoursItem => day.isSameOrAfter(moment.tz(hoursItem.startsAt, 'Asia/Tokyo')) && day.isBefore(moment.tz(hoursItem.endsAt, 'Asia/Tokyo')))
              }

              let lunchHours
              let dinnerHours

              if (hoursForDay && hoursForDay.hours && hoursForDay.hours.lunch && hoursForDay.hours.lunch[daysOfWeekEnglish[dayIndex]]) {
                lunchHours = hoursForDay.hours.lunch[daysOfWeekEnglish[dayIndex]]
              }

              if (hoursForDay && hoursForDay.hours && hoursForDay.hours.dinner && hoursForDay.hours.dinner[daysOfWeekEnglish[dayIndex]]) {
                dinnerHours = hoursForDay.hours.dinner[daysOfWeekEnglish[dayIndex]]
              }

              let holidaysToday = []

              if (holidays) {
                holidaysToday = holidays.filter(holiday => day.isSame(moment.tz(holiday.startsAt, 'Asia/Tokyo'), 'day') || day.isSame(moment.tz(holiday.endsAt, 'Asia/Tokyo'), 'day'))
              }

              const isClosed = (holidaysToday.length > 0 && holidaysToday.some(({ value }) => value))
                || (hoursForDay && hoursForDay.hours && (!lunchHours && !dinnerHours))

              return (
                <td
                  key={dayIndex}
                  className={classNames(classes.cell, {
                    [classes.cellClickable]: isInFuture,
                    [classes.cellGray]: !isThisMonth,
                    [classes.cellDisabled]: isClosed,
                  })}
                  onClick={isInFuture ? () => onClickDate(day) : null}>
                  {!isClosed && (
                    <div className={classes.cellTextContainer}>
                      <Typography
                        variant="body1"
                        className={classes.cellText}>
                        {lunchHours}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.cellText}>
                        {dinnerHours}
                      </Typography>
                    </div>
                  )}
                  {isClosed && (
                    <div className={classes.cellTextContainer}>
                      <Typography
                        variant="body1"
                        className={classNames(classes.cellText, classes.cellTextLight)}>
                        <FormattedMessage
                          id="hours.closed"
                          defaultMessage="Closed" />
                      </Typography>
                    </div>
                  )}
                  {holidaysToday.length > 0 && (
                    <div className={classes.holidaysContainer}>
                      {holidaysToday.map(holiday => (
                        <div
                          key={holiday.code}
                          className={classNames(classes.holiday, {
                            [classes.holidayInactive]: !holiday.value,
                          })}>
                          <Typography
                            variant="body1"
                            className={classNames(classes.holidayText, {
                              [classes.holidayTextInactive]: !holiday.value,
                            })}>
                            {holiday.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                  {isToday && (
                    <div className={classes.today}>
                      <Typography
                        variant="body1"
                        className={classes.todayText}>
                        {day.date()}
                      </Typography>
                    </div>
                  )}
                  {!isToday && (
                    <Typography
                      id="date-text"
                      variant="body1"
                      className={classNames(classes.dateText, {
                        [classes.dateTextGray]: !isThisMonth,
                      })}>
                      {day.date()}
                    </Typography>
                  )}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
})

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: theme.spacing(4, 4, 0, 4),
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 66px)', // HACK for header height
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 0, 2),
      minHeight: 'none',
    },
  },
  topbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topbarLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#292529',
    minWidth: 100,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginLeft: 0,
    },
  },
  changeHoursCta: {
    border: '2px solid #FF6069',
    borderRadius: 4,
    '&:hover': {
      border: '2px solid #FF6069',
      borderRadius: 4,
    }
  },
  calendarContainer: {
    flex: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  calendar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    maxHeight: `calc(100vw - ${DRAWER_WIDTH}px)`,
    minHeight: 'calc(100vh - 120px)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginLeft: 0,
      maxHeight: 'none',
      minHeight: '120vw',
    },
  },
  loading: {

  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

const fetchData = (dispatch, shop) => {
  const today = moment().format('YYYYMMDD')
  const tomorrow = moment().add(1, 'day').format('YYYYMMDD')

  return promiseMap({
    holidays: dispatch(getHolidays()),
    myShopHours: dispatch(getShopHours(shop.id)),
    myShopHoursHistory: dispatch(getShopHoursHistory(shop.id)),
    myShopHolidays: dispatch(getMyShopHolidays(shop.id)),
    reservationsToday: dispatch(getReservationsForDate(shop.id, today)),
    reservationsTomorrow: dispatch(getReservationsForDate(shop.id, tomorrow)),
  })
}

class Hours extends Component {
  state = {
    closedDateLoading: false,
    editHoursOpen: false,
    existingReservationsWarningDialogOpen: false,
    editHoursForDateOpen: false,
    closedDateSuccessOpen: false,
    editDateOpen: false,
    dateToEdit: moment(),
    warningReservations: [],
  }

  constructor(props) {
    super(props)

    let date = this.props.match.params.date

    if (!date) {
      console.log('1')
      date = this.props.hoursDate
      this.props.history.push(`/hours/${date.format(DATE_FORMAT)}`)
    } else {
      console.log('2')
      date = moment(date, DATE_FORMAT)
      props.setHoursDate(date)
    }
  }

  hasLoaded = () => {
    return this.props.holidays.loaded
      && this.props.myShopHours.loaded
      && this.props.myShopHoursHistory.loaded
      && this.props.myShopHolidays.loaded
  }

  onClickDatePrevious = () => {
    const date = this.props.hoursDate.clone().subtract(1, 'month')
    this.props.history.push(`/hours/${date.format(DATE_FORMAT)}`)

    this.props.setHoursDate(date)
  }

  onClickDateNext = () => {
    const date = this.props.hoursDate.clone().add(1, 'month')
    this.props.history.push(`/hours/${date.format(DATE_FORMAT)}`)

    this.props.setHoursDate(date)
  }

  onClickChangeHours = () => {
    this.setState({
      editHoursOpen: true,
    })
  }

  onClickCloseChangeHours = () => {
    this.setState({
      editHoursOpen: false,
    })
  }

  onClickSubmitEditHours = async hours => {
    await this.props.editShopHours({
      shopId: this.props.shop.id,
      hours,
      startsAt: moment().add(1, 'day').format('YYYY-MM-DD'),
    })

    await Promise.all([
      this.props.getShopHours(this.props.shop.id),
      this.props.getShopHoursHistory(this.props.shop.id),
    ])
  }

  onClickEditDate = date => {
    const today = moment()
    const tomorrow = moment().add(1, 'day')

    if (date.isSame(today, 'day') && this.props.reservationsToday.data && this.props.reservationsToday.data.length > 0) {
      this.setState({
        dateToEdit: date,
        existingReservationsWarningDialogOpen: true,
        warningReservations: this.props.reservationsToday.data,
      })
    } else if (date.isSame(tomorrow, 'day') && this.props.reservationsTomorrow.data && this.props.reservationsTomorrow.data.length > 0) {
      this.setState({
        dateToEdit: date,
        existingReservationsWarningDialogOpen: true,
        warningReservations: this.props.reservationsTomorrow.data,
      })
    } else {
      this.setState({
        dateToEdit: date,
        editDateOpen: true,
      })
    }
  }

  onClickCloseEditDate = date => {
    this.setState({
      editDateOpen: false,
    })
  }

  onClickEditHoursForDate = () => {
    this.setState({
      editDateOpen: false,
      editHoursForDateOpen: true,
    })
  }

  onClickSetClosedForDate = async date => {
    this.setState({
      closedDateLoading: true,
    })

    let hours = {
      lunch: {},
      dinner: {},
    }

    if (this.props.myShopHours.data) {
      hours = this.props.myShopHours.data
    }

    const dayName = date.clone().locale('en').format('dddd').toLowerCase()

    delete hours.lunch[dayName]
    delete hours.dinner[dayName]

    await this.props.editShopHours({
      shopId: this.props.shop.id,
      hours,
      startsAt: date.format('YYYY-MM-DD'),
      endsAt: date.clone().add(1, 'day').format('YYYY-MM-DD'),
      important: true,
    })

    await Promise.all([
      this.props.getShopHours(this.props.shop.id),
      this.props.getShopHoursHistory(this.props.shop.id),
    ])

    this.setState({
      closedDateLoading: false,
      editDateOpen: false,
      closedDateSuccessOpen: true,
    })
  }

  onClickCloseEditHoursForDate = () => {
    this.setState({
      editHoursForDateOpen: false,
    })
  }

  onClickSubmitEditHoursForDate = async (hours, date) => {
    await this.props.editShopHours({
      shopId: this.props.shop.id,
      hours,
      startsAt: date.format('YYYY-MM-DD'),
      endsAt: date.clone().add(1, 'day').format('YYYY-MM-DD'),
      important: true,
    })

    await Promise.all([
      this.props.getShopHours(this.props.shop.id),
      this.props.getShopHoursHistory(this.props.shop.id),
    ])
  }

  onClickCloseClosedDateSuccessDialog = () => {
    this.setState({
      closedDateSuccessOpen: false,
    })
  }

  onClickAcceptExistingReservationsWarning = () => {
    this.setState({
      existingReservationsWarningDialogOpen: false,
      editDateOpen: true,
    })
  }

  onClickCloseExistingResetvationsWarningDialog = () => {
    this.setState({
      existingReservationsWarningDialogOpen: false,
    })
  }

  async componentWillMount() {
    let dialog
    let holidays
    let myShopHolidays
    let upcomingHolidays = []

    if (!this.hasLoaded()) {
      this.setState({
        loading: true,
      })

      const results = await fetchData(this.props.dispatch, this.props.shop)

      holidays = results.holidays
      myShopHolidays = results.myShopHolidays
    } else {
      holidays = this.props.holidays.data
      myShopHolidays = this.props.myShopHolidays.data
    }

    this.setState({
      loading: false,
    })
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.shop.id !== this.props.shop.id) {
      this.setState({
        loading: true,
      })

      const results = await fetchData(this.props.dispatch, this.props.shop)

      let upcomingHolidays = []
      const holidays = results.holidays
      const myShopHolidays = results.myShopHolidays

      this.setState({
        loading: false,
      })
    }
  }

  render() {
    const holidays = []

    if (this.props.holidays.loaded && this.props.myShopHolidays.loaded) {
      for (let holiday of this.props.holidays.data) {
        holidays.push({
          ...holiday,
          value: this.props.myShopHolidays.data.some(({ code }) => code === holiday.code),
        })
      }
    }

    return (
      <div className={this.props.classes.container}>
        <EditHoursDialog
          open={this.state.editHoursOpen}
          onClose={this.onClickCloseChangeHours}
          onSubmit={this.onClickSubmitEditHours} />
        <EditHoursForDateDialog
          open={this.state.editHoursForDateOpen}
          date={this.state.dateToEdit}
          onClose={this.onClickCloseEditHoursForDate}
          onSubmit={this.onClickSubmitEditHoursForDate} />
        <ClosedDateSuccessDialog
          open={this.state.closedDateSuccessOpen}
          onClose={this.onClickCloseClosedDateSuccessDialog} />
        <EditDatesDialog
          open={this.state.editDateOpen}
          closedDateLoading={this.state.closedDateLoading}
          date={this.state.dateToEdit}
          onClose={this.onClickCloseEditDate}
          onClickEditHoursForDate={this.onClickEditHoursForDate}
          onClickSetClosedForDate={this.onClickSetClosedForDate} />
        <ExistingReservationsWarningDialog
          open={this.state.existingReservationsWarningDialogOpen}
          reservations={this.state.warningReservations}
          onClickAccept={this.onClickAcceptExistingReservationsWarning}
          onClose={this.onClickCloseExistingResetvationsWarningDialog } />
        <div className={this.props.classes.topbar}>
          <div className={this.props.classes.topbarLeft}>
            <Typography
              variant="body1"
              className={this.props.classes.title}>
              {this.props.hoursDate.format('MMM YYYY')}
            </Typography>
            <div className={classNames(this.props.classes.pageButtons, this.props.classes.pageButtonsMobile)}>
              <IconButton
                size="small"
                onClick={this.onClickDatePrevious}>
                <NavigateBeforeRoundedIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={this.onClickDateNext}>
                <NavigateNextRoundedIcon />
              </IconButton>
            </div>
          </div>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={this.props.classes.changeHoursCta}
            onClick={this.onClickChangeHours}>
            <FormattedMessage
              id="hours.edit-hours"
              defaultMessage="Edit hours" />
          </Button>
        </div>
        <div className={this.props.classes.calendarContainer}>
          <Calendar
            date={this.props.hoursDate}
            hoursHistory={this.props.myShopHoursHistory.data}
            holidays={holidays}
            className={this.props.classes.calendar}
            onClickDate={this.onClickEditDate} />
          {this.state.loading && (
            <CircularProgress
              size={24}
              color="primary"
              className={this.props.classes.ctaProgress} />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const today = moment().format('YYYYMMDD')
  const tomorrow = moment().add(1, 'day').format('YYYYMMDD')

  return ({
    shop: state.features.shop,
    hoursDate: moment(state.features.hoursDate),
    holidays: state.api.holidays.default,
    myShopHours: state.api.myShopHours.default,
    myShopHoursHistory: state.api.myShopHoursHistory.default,
    myShopHolidays: state.api.myShopHolidays.default,
    reservationsToday: state.api.reservationsForDate[today],
    reservationsTomorrow: state.api.reservationsForDate[tomorrow],
  })
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    setHoursDate,
    getShopHours,
    editShopHours,
    getShopHoursHistory,
    getMyShopHolidays,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Hours)))
