import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import {
  Field,
  reduxForm,
} from 'redux-form'
import {
  Checkbox,
  Select,
} from 'redux-form-material-ui'
import {
  injectIntl,
  defineMessages
} from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import green from '@material-ui/core/colors/green'
import CloseIcon from '@material-ui/icons/Close'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import CalendarIcon from '../../components/icons/CalendarIcon'
import Constants, {
  LUNCH_SHOP_OPEN,
  LUNCH_SHOP_CLOSE,
  DINNER_SHOP_OPEN,
  DINNER_SHOP_CLOSE,
} from '../../utils/Constants'

const messages = defineMessages({
  missingField: {
    id: 'edit-hours-dialog.missing-field',
    defaultMessage: 'This field is required.',
  },
  timeRangeInvalid: {
    id: 'edit-hours-dialog.time-range-invalid',
    defaultMessage: 'Closing time must come after opening time.',
  },
  hours: {
    id: 'edit-hours-dialog.hours',
    defaultMessage: 'Hours',
  },
  lunch: {
    id: 'edit-hours-dialog.lunch',
    defaultMessage: 'Lunch',
  },
  dinner: {
    id: 'edit-hours-dialog.dinner',
    defaultMessage: 'Dinner',
  },
})

export const CLOSED_INDICATOR = '--'

const WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
]

const WEEKENDS = [
  'sunday',
  'saturday',
]

const WEEKDAY_INDEXES = [0, 1, 2, 3, 4]
const WEEKEND_INDEXES = [5, 6]

const lunchOpen = moment.tz('Asia/Tokyo').startOf('day').set(LUNCH_SHOP_OPEN)
const lunchClose = moment.tz('Asia/Tokyo').startOf('day').set(LUNCH_SHOP_CLOSE)

const dinnerOpen = moment.tz('Asia/Tokyo').startOf('day').set(DINNER_SHOP_OPEN)
const dinnerClose = moment.tz('Asia/Tokyo').startOf('day').set(DINNER_SHOP_CLOSE)

const LUNCH_TIMES = [CLOSED_INDICATOR]
const DINNER_TIMES = [CLOSED_INDICATOR]

while (!lunchOpen.isAfter(lunchClose)) {
  LUNCH_TIMES.push(lunchOpen.clone().format('kk:mm'))
  lunchOpen.add(15, 'm')
}

while (!dinnerOpen.isAfter(dinnerClose)) {
  DINNER_TIMES.push(dinnerOpen.clone().format('kk:mm'))
  dinnerOpen.add(15, 'm')
}

const getDaysForIndexes = dayIndexes => dayIndexes.map(dayIndex => ({
  key: moment().day(dayIndex + 1).locale('en').format('dddd').toLowerCase(),
  label: moment().day(dayIndex + 1).format('dd'),
}))

export const getWeekdayHours = hours => {
  let start, end

  for (let day of Object.keys(hours)) {
    if (WEEKDAYS.includes(day) && hours[day]) {
      const parts = hours[day].split('-')

      start = parts[0]
      end = parts[1]
    }
  }

  /*
   *  Temporary code blocks to fix some legacy data in the db
   */
  if (start === 'undefined') {
    start = undefined
  }

  if (end === 'undefined') {
    end = undefined
  }

  return {
    start,
    end,
  }
}

export const getWeekendHours = hours => {
  let start, end

  for (let day of Object.keys(hours)) {
    if (WEEKENDS.includes(day)) {
      [start, end] = hours[day].split('-')
    }
  }

  /*
   *  Temporary code blocks to fix some legacy data in the db
   */
  if (start === 'undefined') {
    start = undefined
  }

  if (end === 'undefined') {
    end = undefined
  }

  return {
    start,
    end,
  }
}

const validate = (values, props) => {
  const errors = {}

  for (let dayType of ['weekdays', 'weekends']) {
    for (let mealType of ['lunch', 'dinner']) {
      if (values[`${dayType}-${mealType}-start`] !== CLOSED_INDICATOR && values[`${dayType}-${mealType}-end`] === CLOSED_INDICATOR) {
        errors[`${dayType}-${mealType}-end`] = props.intl.formatMessage(messages.missingField)
      } else if (values[`${dayType}-${mealType}-end`] !== CLOSED_INDICATOR && values[`${dayType}-${mealType}-start`] === CLOSED_INDICATOR) {
        errors[`${dayType}-${mealType}-start`] = props.intl.formatMessage(messages.missingField)
      } else if (values[`${dayType}-${mealType}-start`] !== CLOSED_INDICATOR && values[`${dayType}-${mealType}-end`] !== CLOSED_INDICATOR) {
        const startTime = moment(values[`${dayType}-${mealType}-start`], 'kk:mm')
        const endTime = moment(values[`${dayType}-${mealType}-end`], 'kk:mm')

        /*
         *  Hack for moment js parsing problem with midnight
         */
         const isEndOfDay = endTime.hour() === 0

        if (!isEndOfDay && startTime.isSameOrAfter(endTime)) {
          // errors[`${dayType}-${mealType}-end`] = props.intl.formatMessage(messages.timeRangeInvalid)
        }
      }
    }

    const days = dayType === 'weekdays' ? WEEKDAYS : WEEKENDS
    const selectedDays = days.filter(day => !!values[day])

    if (selectedDays.length > 0) {
      const lunchHoursSelected = values[`${dayType}-lunch-start`] !== CLOSED_INDICATOR && values[`${dayType}-lunch-start`] !== CLOSED_INDICATOR
      const dinnerHoursSelected = values[`${dayType}-dinner-start`] !== CLOSED_INDICATOR && values[`${dayType}-dinner-start`] !== CLOSED_INDICATOR

      if (!lunchHoursSelected && !dinnerHoursSelected) {
        for (let selectedDay of selectedDays) {
          errors[selectedDay] = props.intl.formatMessage(messages.missingField)
        }
      }
    }
  }

  return errors
}

const editHoursStyles = theme => ({
  dialogPaper: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxWidth: 'none',
      maxHeight: 'none',
      borderRadius: 0,
      margin: 0,
    },
  },
  content: {
  },
  form: {
    maxWidth: 450,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: '#292529',
    textAlign: 'center',
  },
  calendarIcon: {
    marginRight: theme.spacing(1),
    marginBottom: -2,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    color: '#292529',
    margin: theme.spacing(4, 0, 2, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0),
    },
  },
  days: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(-1),
    }
  },
  label: {
    fontSize: 16,
    color: '#292529',
    margin: theme.spacing(0, 2, 0, -1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 1, 0, -1),
    }
  },
  times: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  timesLabel: {
    marginRight: theme.spacing(2),
    width: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
      marginTop: theme.spacing(2),
    },
  },
  timesSelects: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  timesSelect: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      minWidth: 150,
    },
  },
  timeDivider: {
    width: theme.spacing(3),
    borderBottom: '1px solid #A8B2B9',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      width: 0,
      height: theme.spacing(2),
      borderRight: '1px solid #A8B2B9',
      margin: theme.spacing(1, 0),
    },
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  cta: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 140,
  },
  ctaSuccess: {
    backgroundColor: green[500],
    boxShadow: `0px 8.72651px 17.453px ${green[500]}`,
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    position: 'absolute',
  },
})

class EditHoursDialog extends Component {
  state = {
    loading:  false,
    success: false,
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    const weekdays = WEEKDAYS.filter(day => values[day])
    const weekends = WEEKENDS.filter(day => values[day])

    const hours = {
      lunch: {},
      dinner: {},
    }

    if (values['weekdays-lunch-start'] !== CLOSED_INDICATOR && values['weekdays-lunch-end'] !== CLOSED_INDICATOR) {
      for (let day of weekdays) {
        hours.lunch[day] = `${values['weekdays-lunch-start']}-${values['weekdays-lunch-end']}`
      }
    }

    if (values['weekdays-dinner-start'] !== CLOSED_INDICATOR && values['weekdays-dinner-end'] !== CLOSED_INDICATOR) {
      for (let day of weekdays) {
        hours.dinner[day] = `${values['weekdays-dinner-start']}-${values['weekdays-dinner-end']}`
      }
    }

    if (values['weekends-lunch-start'] !== CLOSED_INDICATOR && values['weekends-lunch-end'] !== CLOSED_INDICATOR) {
      for (let day of weekends) {
        hours.lunch[day] = `${values['weekends-lunch-start']}-${values['weekends-lunch-end']}`
      }
    }

    if (values['weekends-dinner-start'] !== CLOSED_INDICATOR && values['weekends-dinner-end'] !== CLOSED_INDICATOR) {
      for (let day of weekends) {
        hours.dinner[day] = `${values['weekends-dinner-start']}-${values['weekends-dinner-end']}`
      }
    }

    if (this.props.onSubmit) {
      await this.props.onSubmit(hours)
    }

    this.setState({
      loading: false,
      success: true,
    })

    setTimeout(() => {
      this.props.onClose()

      setTimeout(() => {
        this.setState({ success: false })
      }, 100)
    }, Constants.FAKE_LATENCY_MS)
  }

  render() {
    const weekdays = getDaysForIndexes(WEEKDAY_INDEXES)
    const weekends = getDaysForIndexes(WEEKEND_INDEXES)

    return (
      <Dialog
        open={this.props.open}
        fullWidth
        maxWidth="md"
        classes={{
          paper: this.props.classes.dialogPaper,
        }}
        onClose={this.props.onClose}>
        <DialogTitle className={this.props.classes.title}>
          <CalendarIcon className={this.props.classes.calendarIcon} />
          <FormattedMessage
            id="edit-hours-dialog.title"
            defaultMessage="Edit hours" />
          <IconButton
            aria-label="Close"
            className={this.props.classes.closeButton}
            onClick={this.props.onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          className={this.props.classes.content}>
          {this.props.myShopHoursHistory.data && (
            <form className={this.props.classes.form}>
              <Typography
                variant="body1"
                className={this.props.classes.subtitle}>
                <FormattedMessage
                  id="edit-hours-dialog.weekdays-title"
                  defaultMessage="Weekdays" />
              </Typography>
              <div className={this.props.classes.days}>
                {weekdays.map(day => [(
                  <Field
                    key={`${day.key}-checkbox`}
                    name={day.key}
                    component={Checkbox}
                    color="primary" />
                ), (
                  <Typography
                    key={`${day.key}-label`}
                    variant="body1"
                    className={this.props.classes.label}>
                    {day.label}
                  </Typography>
                )])}
              </div>
              <Typography
                variant="body1"
                className={this.props.classes.subtitle}>
                {this.props.intl.formatMessage(messages.hours)}
              </Typography>
              <div className={this.props.classes.times}>
                <Typography
                  variant="body1"
                  className={this.props.classes.timesLabel}>
                  {this.props.intl.formatMessage(messages.lunch)}
                </Typography>
                <div className={this.props.classes.timesSelects}>
                  <Field
                    name="weekdays-lunch-start"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {LUNCH_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                  <div className={this.props.classes.timeDivider} />
                  <Field
                    name="weekdays-lunch-end"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {LUNCH_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
              <div className={this.props.classes.times}>
                <Typography
                  variant="body1"
                  className={this.props.classes.timesLabel}>
                  {this.props.intl.formatMessage(messages.dinner)}
                </Typography>
                <div className={this.props.classes.timesSelects}>
                  <Field
                    name="weekdays-dinner-start"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {DINNER_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                  <div className={this.props.classes.timeDivider} />
                  <Field
                    name="weekdays-dinner-end"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {DINNER_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
              <Divider className={this.props.classes.divider} />
              <Typography
                variant="body1"
                className={this.props.classes.subtitle}>
                <FormattedMessage
                  id="edit-hours-dialog.weekends-title"
                  defaultMessage="Weekends" />
              </Typography>
              <div className={this.props.classes.days}>
                {weekends.map(day => [(
                  <Field
                    key={`${day.key}-checkbox`}
                    name={day.key}
                    component={Checkbox}
                    color="primary" />
                ), (
                  <Typography
                    key={`${day.key}-label`}
                    variant="body1"
                    className={this.props.classes.label}>
                    {day.label}
                  </Typography>
                )])}
              </div>
              <Typography
                variant="body1"
                className={this.props.classes.subtitle}>
                {this.props.intl.formatMessage(messages.hours)}
              </Typography>
              <div className={this.props.classes.times}>
                <Typography
                  variant="body1"
                  className={this.props.classes.timesLabel}>
                  {this.props.intl.formatMessage(messages.lunch)}
                </Typography>
                <div className={this.props.classes.timesSelects}>
                  <Field
                    name="weekends-lunch-start"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {LUNCH_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                  <div className={this.props.classes.timeDivider} />
                  <Field
                    name="weekends-lunch-end"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {LUNCH_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
              <div className={this.props.classes.times}>
                <Typography
                  variant="body1"
                  className={this.props.classes.timesLabel}>
                  {this.props.intl.formatMessage(messages.dinner)}
                </Typography>
                <div className={this.props.classes.timesSelects}>
                  <Field
                    name="weekends-dinner-start"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {DINNER_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                  <div className={this.props.classes.timeDivider} />
                  <Field
                    name="weekends-dinner-end"
                    component={Select}
                    variant="outlined"
                    className={this.props.classes.timesSelect}>
                    {DINNER_TIMES.map(time => (
                      <MenuItem
                        key={time}
                        value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={!this.state.success && (this.props.pristine || this.props.invalid || this.state.loading)}
                className={classNames(this.props.classes.cta, {
                  [this.props.classes.ctaSuccess]: this.state.success,
                })}
                onClick={this.props.handleSubmit(this.onClickSubmit)}>
                {!this.state.success && (
                  <FormattedMessage
                    id="edit-hours-dialog.save"
                    defaultMessage="Save" />
                )}
                {this.state.success && (<CheckRoundedIcon />)}
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    className={this.props.classes.buttonProgress} />
                )}
              </Button>
            </form>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  let initialValues = {
    'weekdays-lunch-start': CLOSED_INDICATOR,
    'weekdays-lunch-end': CLOSED_INDICATOR,
    'weekdays-dinner-start': CLOSED_INDICATOR,
    'weekdays-dinner-end': CLOSED_INDICATOR,
    'weekends-lunch-start': CLOSED_INDICATOR,
    'weekends-lunch-end': CLOSED_INDICATOR,
    'weekends-dinner-start': CLOSED_INDICATOR,
    'weekends-dinner-end': CLOSED_INDICATOR,
    ...WEEKDAYS.reduce((memo, day) => ({ ...memo, [day]: false }), {}),
    ...WEEKENDS.reduce((memo, day) => ({ ...memo, [day]: false }), {}),
  }

  let weekdayLunchHours
  let weekdayDinnerHours
  let weekendLunchHours
  let weekendDinnerHours

  if (state.api.myShopHoursHistory.default.data) {
    const regularHours = state.api.myShopHoursHistory.default.data.filter(hours => !hours.important)

    if (regularHours.length > 0) {
      const hoursRange = state.api.myShopHoursHistory.default.data[state.api.myShopHoursHistory.default.data.length - 1]

      if (hoursRange) {
        initialValues = [...Object.keys(hoursRange.hours.lunch), ...Object.keys(hoursRange.hours.dinner)].reduce((memo, day) => {
          memo[day] = true
          return memo
        }, initialValues)

        weekdayLunchHours = getWeekdayHours(hoursRange.hours.lunch)
        weekdayDinnerHours = getWeekdayHours(hoursRange.hours.dinner)
        weekendLunchHours = getWeekendHours(hoursRange.hours.lunch)
        weekendDinnerHours = getWeekendHours(hoursRange.hours.dinner)

        initialValues['weekdays-lunch-start'] = weekdayLunchHours.start || CLOSED_INDICATOR
        initialValues['weekdays-lunch-end'] = weekdayLunchHours.end || CLOSED_INDICATOR
        initialValues['weekdays-dinner-start'] = weekdayDinnerHours.start || CLOSED_INDICATOR
        initialValues['weekdays-dinner-end'] = weekdayDinnerHours.end || CLOSED_INDICATOR
        initialValues['weekends-lunch-start'] = weekendLunchHours.start || CLOSED_INDICATOR
        initialValues['weekends-lunch-end'] = weekendLunchHours.end || CLOSED_INDICATOR
        initialValues['weekends-dinner-start'] = weekendDinnerHours.start || CLOSED_INDICATOR
        initialValues['weekends-dinner-end'] = weekendDinnerHours.end || CLOSED_INDICATOR
      }
    }
  }

  return {
    initialValues,
    myShopHoursHistory: state.api.myShopHoursHistory.default,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(reduxForm({
  form: 'edit-hours',
  validate,
  enableReinitialize: true,
})((withStyles(editHoursStyles)(EditHoursDialog)))))
